<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Min </label>
              <InputNumber
                v-model="form.min"
                :minFractionDigits="0"
                :class="{
                  'p-invalid': v$.form.min.$invalid && submitted,
                }"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.min.$invalid && submitted) ||
                  v$.form.min.$pending.$response
                "
                class="p-error"
                >{{ v$.form.min.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Max </label>
              <InputNumber
                v-model="form.max"
                placeholder="Rp 0"
                :minFractionDigits="0"
                :class="{
                  'p-invalid': v$.form.max.$invalid && submitted,
                }"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.max.$invalid && submitted) ||
                  v$.form.max.$pending.$response
                "
                class="p-error"
                >{{ v$.form.max.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Perkiraan Akun</label>
              <Dropdown
                v-model="form.coa_item_id"
                :filter="true"
                :options="listPerkiraanAkun"
                class="w-full"
                :class="{
                  'p-invalid': v$.form.coa_item_id.$invalid && submitted,
                }"
                input-class="w-full"
                optionValue="id"
                :optionLabel="perkiraanLabel"
                placeholder="Perkiraan Akun"
              >
              </Dropdown>
              <small
                v-if="
                  (v$.form.coa_item_id.$invalid && submitted) ||
                  v$.form.coa_item_id.$pending.$response
                "
                class="p-error"
              >
                {{ v$.form.coa_item_id.required.$message }}
              </small>
            </div>
            <div class="field col-12 md:col-12">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                :options="['active', 'inactive']"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listPerkiraanAkun: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    perkiraanLabel(e) {
      return e.kode + ' - ' + e.nama
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        min: {
          required: helpers.withMessage('Min harus diisi.', required),
        },
        max: {
          required: helpers.withMessage('Max harus diisi.', required),
        },
        // bunga: {
        //   required: helpers.withMessage('Bunga harus diisi.', required),
        // },
        coa_item_id: {
          required: helpers.withMessage('Perkiraan Akun harus diisi.', required),
        },
      },
    }
  },
}
</script>

